import React from 'react'

function NinjaKoala() {
    return (
        <div id="NinjaKoala" className="w-full custom-bg-Hero md:px-4 px-8">
            <div className="w-full max-w-[1400px] md:h-screen md:py-0 py-24 m-auto flex md:justify-between justify-center items-center md:flex-row flex-col">
                {/* Left Part */}
                <div className='w-full md:w-[50%] flex-col justify-start items-center md:pr-[50px]
                '>
                    <h1 className='font-PixelEmulator text-[50px] text-white text-center md:text-left'>NINJA KOALA</h1>
                    <p className='text-white text-[20px] leading-10 mt-8 font-TerminalMedium tracking-wider text-center md:text-left'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard</p>
                    <button className="w-full relative md:w-[350px] h-[90px] flex justify-center items-center mt-16 ">
                        <img
                            src="/btn-bg.png"
                            alt="img"
                            className="w-full h-full absolute object-fill"
                        />
                        <p className="absolute text-white font-Tahoma font-bold text-[20px]">
                            Go to Launchpad
                        </p>
                    </button>
                </div>
                {/*end of  Left Part */}


                {/* Right Part */}
                <div className="w-full md:w-[45%] p-1 rounded-[12px] border-2 border-dotted border-white md:mt-0 mt-20">
                    <img
                        src="/lunch2.png"
                        alt="img"
                        className="w-full h-full object-contain"
                    />
                </div>
                {/* end of Right Part */}
            </div>
        </div>
    )
}

export default NinjaKoala;