import React from 'react'
import Hero from '../NinjaKoala/Hero';
import LiveLaunches from './LiveLaunches';
import Upcomming from "./Upcomming";
import PastLaunches from "./PastLaunches";


function NinjaKoala() {
    return (
        <div>
            <Hero />
            <LiveLaunches />
            <Upcomming />
            <PastLaunches />
        </div>
    )
}

export default NinjaKoala