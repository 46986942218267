import React from "react";

function Hero() {
  return (
    <>
      <div id="Home" className="w-full custom-bg-Hero lg:px-4 px-8">
        <div className="w-full max-w-[1400px] lg:h-screen m-auto flex justify-center items-center lg:flex-row flex-col lg:pt-0 pt-8">
          {/* Left */}
          <div className="w-full lg:w-[45%] h-full flex justify-center lg:items-start items-center flex-col ">
            <h1 className="font-PixelEmulator text-[50px] text-white lg:text-start text-center">
              Koala labs
              <br /> Builder of future
            </h1>
            <h3 className="text-white text-[20px] leading-10 mt-8 font-TerminalMedium lg:text-start text-center">
              Koala Labs is community of builders to create metatools in $APTOS
              ecosystem with first cross chain Metaverse.
            </h3>
            <button className="relative w-[350px] h-[90px] flex justify-center items-center mt-16">
              <img
                src="/btn-bg.png"
                alt="img"
                className="w-full h-full absolute object-fill"
              />
              <p className="absolute text-white font-Tahoma font-bold text-[20px]">
                Join Our Community
              </p>
            </button>
          </div>
          {/* Right */}
          <div className="w-full lg:w-[55%] h-full flex justify-center items-end">
            <img
              src="/heroNFT.png"
              alt="img"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
