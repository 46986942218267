import React from "react";
import { Accordion, Typography } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdOutlineExpandMore } from "react-icons/md";

function Faq() {
  return (
    <div id="Faq" className="w-full lg:px-4 px-8 mt-[8rem] lg:mt-20rem">
      <div className="w-full m-auto max-w-[1400px] flex flex-col justify-center items-center">
        <h1 className="font-PixelEmulator text-[35px] lg:[20px] text-white text-center lg:text-start mb-[50px]">
          FRQUENTLY ASKED QUESTIONS
        </h1>

        {questions.map((item, index) => {
          return (
            <div
              key={item.id}
              className=" flex justify-center items-center mt-6 font-PixelEmulator lg:max-w-[1100px]"
            >
              <Accordion sx={{ background: "transparent", boxShadow: "none", border: "1px dashed #2cb21d", py: 2 }} >
                <AccordionSummary
                  expandIcon={
                    <MdOutlineExpandMore color="#2cb21d" size="26px" />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="text-white font-TerminalMedium" >{item.question}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="text-white font-TerminalMedium" >{item.answer}</p>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const questions = [
  {
    question:
      "It is a long established fact that a reader will be distracted by the readable content ?",
    answer:
      "using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their",
    id: 1,
  },
  {
    question: "It is a long established fact that a reader will be distracted ?",
    answer:
      "using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their",
    id: 2,
  },
  {
    question: "It is a long established fact that ?",
    answer:
      "using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their",
    id: 3,
  },
  {
    question:
      "It is a long established fact that a reader will be distracted by the readable content of a page ?",
    answer:
      "using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their",
    id: 4,
  },
  {
    question:
      "It is a long established fact that a reader will be distracted by the readable content of a page ?",
    answer:
      "using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their",
    id: 5,
  },
];

export default Faq;
