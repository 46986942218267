import React from "react";

function SecoundSection() {
  return (
    <div className="w-full lg:px-4 px-8 py-16 lg:py-[0px] ">
      <div className="w-full, m-auto max-w-[1400px] lg:h-screen flex justify-center items-start flex-col">
        <h1 className="w-full font-PixelEmulator text-[30px] lg:text-[40px]  text-white text-center lg:text-left  mb-8">
          RELATED COLLECTION
        </h1>

        <div className="grid md:grid-cols-3 grid-cols-1 gap-12 justify-center items-center w-full">
          {NftObject.map((nft, index) => {
            return (
              <div
                key={nft.id}
                className={`w-full bg-[${nft.backColor}] flex-col flex -p-[20px] rounded-[12px] border-2 border-dotted border-white p-[10px]`}
              >
                <img src={nft.image} alt="NFT" className="rounded-lg" />
                <div className=" flex justify-between items-start  text-white mt-2 px-2">
                  <h1 className="font-PixelEmulator text-[20px]">
                    {nft.nftName}
                  </h1>
                  <p className="font-PixelEmulator">
                    <span className="text-[#452d92]">{nft.price}</span>
                    <br /> 2$APT
                  </p>
                  {/* bottom border */}
                </div>
                <div className="border-dashed border-b-2  w-full border-[#2cb21d] mt-2 mb-2"></div>

                <div className="flex justify-between items-center text-white text-[14px] px-2">
                  <p className="font-PixelEmulator">
                    ITEMS: <br />{" "}
                    <span className="text-[#452d92]">{nft.items}</span>
                  </p>
                  <p className="font-PixelEmulator text-right">
                    start in time <br />{" "}
                    <span className="text-[#452d92]">{nft.startsInTime}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const NftObject = [
  {
    id: "1",
    image: "/lunch2.png",
    backColor: "#0b0126",
    nftName: "NFT",
    price: "2$APT",
    items: "2222",
    startsInTime: "00:45",
  },
  {
    id: "2",
    image: "/lunch1.png",
    backColor: "#190b48",
    nftName: "NFT",
    price: "2$APT",
    items: "2222",
    startsInTime: "00:45",
  },
  {
    id: "3",
    image: "/lunch2.png",
    backColor: "#0b0126",
    nftName: "NFT",
    price: "2$APT",
    items: "2222",
    startsInTime: "00:45",
  },
];
export default SecoundSection;
