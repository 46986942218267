import React from "react";

function AboutUs() {
  return (
    <div id="About" className="w-full py-40 lg:px-4 px-8">
      <div className="w-full m-auto max-w-[1400px] h-full flex flex-col lg:flex-row justify-center items-center">


        <div className="w-full lg:w-[40%] h-full flex justify-center items-center lg:mr-6 overflow-hidden">
          <img src="/AboutNFT.png" alt="aboutImage" className="w-full h-full object-cover rounded-[15px]" />
        </div>

        <div className="w-full lg:w-[60%] flex flex-col justify-start lg:items-start items-center flex-wrap lg:ml-6 lg:mt-0 mt-8">
          <h1 className="font-PixelEmulator text-[35px] text-white mt-4 lg:mt-0 ">
            ABOUT US
          </h1>
          <p className="text-white font-TerminalMedium leading-8 text-center lg:text-start">
            Koala Labs is community of builders to create metatools in $APTOS
            ecosystem with first cross chain Metaverse Koala Labs is community
            of builders to create metatools in $APTOS ecosystem with first cross
            chain Metaverse Koala Labs is community of builders to create
            metatools in $APTOS ecosystem with first cross chain Metaverse.
          </p>

          <div className="flex flex-col sm:flex-row gap-6 lg:gap-0 justify-center lg:justify-start mt-10 w-full lg:w-auto">
            {/* utility tools */}
            <div className="font-PixelEmulator rounded-lg border-solid border-2 border-[#593bb4] w-full lg:w-[130px] flex gap-4 flex-col justify-center items-center text-center text-white py-3 mr-6">
              <p className="text-[26px] text-[#593bb4]">05</p>
              <p className="text-[12px] normal-case">Utility Tools</p>
            </div>
            {/* community tools */}
            <div className="font-PixelEmulator rounded-lg border-solid border-2 border-[#593bb4] w-full lg:w-[185px] flex flex-col gap-4 justify-center text-center items-center text-white py-3 mr-6">
              <p className="text-[26px] text-[#593bb4]">10</p>
              <p className="text-[13px] normal-case">Community Support</p>
            </div>
            {/* user registered with out tools */}
            <div className="font-PixelEmulator rounded-lg border-solid border-2 border-[#593bb4] w-full lg:w-[180px] flex flex-col gap-4 justify-center items-center text-center  text-white py-3">
              <p className="text-[26px] text-[#593bb4]">10000+</p>
              <p className="text-[12px] normal-case">
                user registered with out tools
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default AboutUs;
