import React from "react";

function Footer() {
  return (
    <div className="w-full bg-footer py-16 ">
      <div className="w-full m-auto max-w-[1400px] flex flex-col justify-center items-center">
        {/* stright line */}
        <div className="w-full border-solid border-b-2 border-[#130737] flex justify-center items-center mt-[5rem] lg:mt-0"></div>
        <div className="flex flex-col lg:flex-row w-full justify-between items-center text-white font-TerminalMedium mt-[3rem] text-[12px] tracking-[4px]">
          <p className="mb-3 lg:mb-0">Copyright 2022. All Right Reserved</p>
          <p>Terms and Conditions</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
