import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Global/Header/Header";
import Footer from "./components/Global/Footer/Footer";
import Home from "./components/Home/Home";
import Lunch from "./components/LaunchDetail/LaunchDetail";
import NinjaKoala from "./components/NinjaKoala/NinjaKoala";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/lunch" element={<Lunch />} />
          <Route path="/ninjakoala" element={<NinjaKoala />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
