import React from 'react';
import Hero from "./Hero/Hero";
import AboutUs from "./AboutUs/AboutUs";
import Roadmap from "./Roadmap/Roadmap";
import NftLaunced from "./NftLaunced/NftLaunced";
import UpcommingLaunches from "./UpcommingLaunches/UpcommingLaunches";
import Team from "./Team/Team";
import FAQs from "./Faq/Faq";

function Home() {
  return (
    <>
    <Hero />
    <AboutUs />
    <Roadmap />
    <NftLaunced />
    <UpcommingLaunches />
    <Team />
    <FAQs />
    </>
  )
}

export default Home