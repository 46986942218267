import React from "react";
import { AiOutlineTwitter } from "react-icons/ai";

function Team() {
  return (
    <div id="Team" className=" w-full m-auto max-w-[1400px] justify-center items-center py-12 lg:px-4 px-8">
      <h1 className="font-PixelEmulator text-[35px] lg:[20px] text-white text-center">
        our team
      </h1>

      <div className="w-full grid md:grid-cols-3 grid-cols-1 gap-x-10 gap-y-10 mt-16">
        {/* one member */}
        <div className="flex w-full flex-col justify-center items-center mr-0 lg:mr-8">
          <img
            src="/lunch1.png"
            alt="member1"
            className="w-full border-dashed rounded-lg "
          />
          <div className="flex w-full justify-start items-center text-white font-PixelEmulator mt-4">
            <p>Lorem ipsum</p>
            <AiOutlineTwitter
              size="30px"
              color="white"
              className=" rounded-full p-[4px] bg-[#452d92] ml-4"
            />
          </div>
        </div>

        {/* second member */}
        <div className="flex w-full flex-col  justify-center items-center mr-0 lg:mr-8 mt-4 lg:mt-0">
          <img
            src="/lunch2.png"
            alt="member1"
            className="w-full rounded-[12px] border-2 border-dotted border-white p-1"
          />
          <div className="flex w-full justify-start items-center text-white font-PixelEmulator mt-4">
            <p>Lorem ipsum</p>
            <AiOutlineTwitter
              size="30px"
              color="black"
              className="bg-white rounded-full p-[4px] ml-4"
            />
          </div>
        </div>

        {/* third member */}

        <div className="flex w-full flex-col justify-center items-center mt-4 lg:mt-0">
          <img
            src="/lunch1.png"
            alt="member1"
            className="w-full border-dashed rounded-lg "
          />
          <div className="flex w-full justify-start items-center text-white font-PixelEmulator mt-4">
            <p>Lorem ipsum</p>
            <AiOutlineTwitter
              size="30px"
              color="white"
              className="rounded-full p-[4px] bg-[#452d92] ml-4"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
