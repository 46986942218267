import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";
import { NavLink } from "react-router-dom";

function Nfts() {
  return (
    <div className="w-full flex justify-center items-center">
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        modules={[Autoplay, Pagination]}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
        className="mySwiper"
      >
        {[
          NftObject.map((nft, index) => {
            return (
              <SwiperSlide>
                <NavLink to={"/lunch"}
                  key={nft.id}
                  className={`w-full bg-[${nft.backColor}] flex-col flex -p-[20px] rounded-[12px] border-2 border-dotted border-white p-[10px] rounded-lg mb-[7rem]`}
                >
                  <img src={nft.image} alt="NFT" className="rounded-lg" />
                  <div className="flex justify-between items-start  text-white mt-2 px-2">
                    <h1 className="font-PixelEmulator text-[20px]">
                      {nft.nftName}
                    </h1>
                    <p className="font-PixelEmulator">
                      <span className="text-[#452d92]">{nft.price}</span>
                      <br /> 2$APT
                    </p>
                    {/* bottom border */}
                  </div>
                  <div className="border-dashed border-b-2  w-full border-[#2cb21d] mt-2 mb-2"></div>

                  <div className="flex justify-between items-center text-white text-[14px] px-2">
                    <p className="font-PixelEmulator">
                      ITEMS: <br />{" "}
                      <span className="text-[#452d92]">{nft.items}</span>
                    </p>
                    <p className="font-PixelEmulator text-right">
                      start in time <br />{" "}
                      <span className="text-[#452d92]">{nft.startsInTime}</span>
                    </p>
                  </div>
                </NavLink>
              </SwiperSlide>
            );
          }),
        ]}
      </Swiper>{" "}
      .
    </div>
  );
}

const NftObject = [
  {
    id: "1",
    image: "/lunch2.png",
    backColor: "#0b0126",
    nftName: "NFT",
    price: "2$APT",
    items: "2222",
    startsInTime: "00:45",
  },
  {
    id: "2",
    image: "/lunch1.png",
    backColor: "#190b48",
    nftName: "NFT",
    price: "2$APT",
    items: "2222",
    startsInTime: "00:45",
  },
  {
    id: "3",
    image: "/lunch2.png",
    backColor: "#0b0126",
    nftName: "NFT",
    price: "2$APT",
    items: "2222",
    startsInTime: "00:45",
  },
  {
    id: "4",
    image: "/lunch1.png",
    backColor: "#190b48",
    nftName: "NFT",
    price: "2$APT",
    items: "2222",
    startsInTime: "00:45",
  },
  {
    id: "5",
    image: "/lunch2.png",
    backColor: "#0b0126",
    nftName: "NFT",
    price: "2$APT",
    items: "2222",
    startsInTime: "00:45",
  },
  {
    id: "6",
    image: "/lunch1.png",
    backColor: "#190b48",
    nftName: "NFT",
    price: "2$APT",
    items: "2222",
    startsInTime: "00:45",
  },
  {
    id: "7",
    image: "/lunch2.png",
    backColor: "#190b48",
    nftName: "NFT",
    price: "2$APT",
    items: "2222",
    startsInTime: "00:45",
  },
];

export default Nfts;
