import React from "react";
import { NavLink } from "react-router-dom";

function NftLaunced() {
  return (
    <div id="Gondor" className="w-full lg:mt-[14rem] md:mt-[10rem] mt-20 ">
      <div className="NftLaunced-bg w-full flex justify-center items-center">
        <div className=" flex flex-col justify-center items-center w-full lg:w-[900px] py-10 px-4 lg:px-0">
          <h1 className="font-PixelEmulator text-[20px] lg:text-[35px] text-white mb-8">
            NFT LAUNCHPAD CHONDORD
          </h1>
          <p className="text-white text-[18px] font-TerminalMedium leading-[38px] tracking-[3px] text-center">
            Lauch your NFT collection in minutes with custome designas per your
            website theme. pay 5-10% for mint 0% Royality{" "}
          </p>
          <NavLink to={"/ninjakoala"} className="relative w-[300px] h-[75px] flex justify-center items-center mt-10" >
            <img
              src="/btn-bg.png"
              alt="img"
              className="w-full h-full absolute object-fill"
            />
            <p className="absolute text-white font-Tahoma font-bold text-[18px]">
              Ninja Koala
            </p>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default NftLaunced;
