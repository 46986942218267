import React from "react";
import { RiCheckboxBlankFill } from "react-icons/ri";

function Roadmap() {
  return (
    <div id="Roadmap" className="w-full lg:pb-[16rem] md:py-8 lg:px-4 px-8">
      <div className="w-full m-auto max-w-[1400px] h-full flex flex-col justify-center items-center">
        <h1 className="font-PixelEmulator text-[35px] md:[20px] text-white ">
          ROADMAP
        </h1>
        <div className="w-full flex flex-col justify-center items-center">
          {/* top part */}
          <div className="flex w-full flex-col md:flex-row items-center justify-center md:mt-[16rem] mt-8">
            <div className="text-white w-full md:w-[420px] min-h-[450px] flex flex-col justify-center items-start lg:gap-8 gap-4 font-Terminal Medium text-[13px] p-8 pb-[58px] md:mr-4 lg:mr-8 md:-mt-[10rem] rounded-[12px] border-2 border-dotted border-white">
              <h1 className="text-[25px] font-PixelEmulator">Q4-2022</h1>
              <p className="flex gap-3 justify-start items-center tracking-[4px]">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Website
                Building
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px]">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Community
                Building
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px] leading-5">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Discord
                Sales and Listing Bot Launch
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px] leading-5">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Whitepaper
                Launch of Koala Labs
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px] leading-5">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> NFT
                Launchpad live on mainnet
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px] leading-5">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> NFT
                Collection Launch
              </p>
            </div>

            <div className="text-white w-full md:w-[420px] min-h-[450px] flex flex-col justify-center items-start lg:gap-8 gap-4 font-Terminal Medium text-[13px] p-8 pb-[58px] md:ml-4 lg:ml-8 md:-mb-[10rem] md:mt-0 mt-8 rounded-[12px] border-2 border-dotted border-white">
              <h1 className="text-[25px] font-PixelEmulator">Q1-2023</h1>
              <p className="flex gap-3 justify-start items-center tracking-[4px]">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Launch of 20+ NFT
                communities with our launchpad
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px]">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Staking as a service
                launchpad
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px] leading-5">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Discord NFT Verification
                Bot Launch
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px] leading-5">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Raffle and Auction
                Marketplace
              </p>
            </div>
          </div>

          {/* bottom div */}
          <div className="flex w-full flex-col md:flex-row items-center justify-center md:mt-[16rem] mt-2">
            <div className="text-white w-full md:w-[420px] min-h-[450px] flex flex-col justify-center items-start lg:gap-8 gap-4 font-Terminal Medium text-[13px] p-8 pb-[58px] md:mr-8 md:-mt-[12rem] mt-8 rounded-[12px] border-2 border-dotted border-white">
              <h1 className="text-[25px] font-PixelEmulator">Q2-2023</h1>
              <p className="flex gap-3 justify-start items-center tracking-[4px]">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Final testing of our
                Metaverse Beta version
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px]">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Onboarding communities for
                our metaverse
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px] leading-5">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Discord sniper bot
              </p>
            </div>

            <div className="text-white w-full md:w-[420px] min-h-[450px] flex flex-col justify-center items-start lg:gap-8 gap-4 font-Terminal Medium text-[13px] p-8 pb-[70px] md:pb-[58px] md:ml-8 md:-mb-[8rem] mt-10 rounded-[12px] border-2 border-dotted border-white">
              <h1 className="text-[25px] font-PixelEmulator">Q4-2023</h1>
              <p className="flex gap-3 justify-start items-center tracking-[4px]">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> $X token launch
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px]">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Airdrop token to NFT holders
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px] leading-5">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Metaverse public launch
              </p>
              <p className="flex gap-3 justify-start items-center tracking-[4px] leading-5">
                <RiCheckboxBlankFill color="#593bb4" size="13px" /> Beyond the metaverse…
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
