import React from 'react';
import { Link } from 'react-scroll';
// import component 👇
import Drawer from 'react-modern-drawer'
//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { Cross as Hamburger } from 'hamburger-react'


function Header() {
    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    return (
        <>
            <div className='w-full bg-[#0b022d] px-4' >
                <div className='w-full max-w-[1400px] h-[90px] m-auto flex justify-between items-center' >
                    {/* Logo */}
                    <img src="/logo.png" alt="logo" className='w-[70px] h-[70px] object-contain ' />

                    {/* Nav Menus */}
                    <div className='lg:flex hidden justify-center items-center' >
                        {
                            Menus?.map((menu, index) => {
                                return <Link
                                    key={index}
                                    to={menu.Name}
                                    spy={true}
                                    smooth={true}
                                    hashSpy={true}
                                    offset={50}
                                    duration={1000}
                                    className="text-white font-PixelEmulator text-[16px] cursor-pointer mr-10"
                                    activeClass="active"
                                >
                                    {menu.Name}
                                </Link>
                            })
                        }

                    </div>
                    <div className='lg:hidden block' >
                        <Hamburger color='white' toggled={isOpen} toggle={setIsOpen} />
                    </div>
                </div>
            </div>
            {/* Drawer */}
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                style={{ background: "#0b022d", width: '70%' }}
            >
                {/* Nav Menus */}
                <div className='w-full flex justify-center items-center flex-col p-6' >
                    {
                        Menus?.map((menu, index) => {
                            return <Link
                                onClick={toggleDrawer}
                                key={index}
                                to={menu.Name}
                                spy={true}
                                smooth={true}
                                hashSpy={true}
                                offset={50}
                                duration={1000}
                                className="text-white font-PixelEmulator text-[16px] cursor-pointer mt-10"
                                activeClass="active"
                            >
                                {menu.Name}
                            </Link>
                        })
                    }

                </div>
            </Drawer>
        </>
    )
}

export default Header

const Menus = [
    {
        Name: "Home",
    },
    {
        Name: "About",
    },
    {
        Name: "Roadmap",
    },
    {
        Name: "Gondor",
    },
    {
        Name: "Team",
    },
    {
        Name: "Faq",
    }
]