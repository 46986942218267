import React from 'react'
import FirstSection from './FirstSection'
import SecoundSection from './SecSection'

function LaunchDetail() {
  return (
    <div>
        <FirstSection/>
        <SecoundSection/>
    </div>
  )
}

export default LaunchDetail