import React, { useState } from "react";
import { Slider } from "@mui/material";
import { AiFillLock } from 'react-icons/ai';
import { AiOutlineUnlock } from 'react-icons/ai';

function FirstSection() {

  const [Mint_Stage, Set_Mint_Stage] = useState(true);
  const [WhiteList, Set_WhiteList] = useState(false);

  function valuetext(value) {
    return `${value}°C`;
  }

  return (
    <div className="w-full custom-bg-Hero-nanja lg:px-4 px-8 lg:py-10 py-8">
      <div className="w-full max-w-[1400px] lg:min-h-screen m-auto flex items-center lg:justify-between justify-center lg:flex-row flex-col py-4">
        {/* left part */}

        {/* Main */}
        <div className="w-full lg:w-[50%] flex flex-col gap-6 lg:gap-4 justify-center items-start">
          <h1 className="font-PixelEmulator text-[26px] text-white lg:text-start text-center">
            Ninja Koala
          </h1>
          <p className="font-TerminalMedium text-[18px] text-white">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus inventore quas suscipit aliquam. Porro, at eligendi? Vitae soluta ex accusamus animi libero, iure commodi esse, necessitatibus sequi, fuga est asperiores nisi culpa. Architecto doloribus quas exercitationem iusto, corporis ea doloremque iure eius ullam consequatur distinctio, quae, consequuntur officiis esse nihil?
          </p>
          <div className="flex justify-center items-center" >
            <p className="font-TerminalMedium text-[16px] text-white mr-3">
              Totlat items: 1.000
            </p>
            <p className="font-TerminalMedium text-[16px] text-white mr-3">
              Price: 1.50
            </p>
          </div>
          <div className="font-TerminalMedium text-[16px] text-white mt-4">
            <button onClick={() => {
              Set_Mint_Stage(true);
              Set_WhiteList(false);
            }} className={Mint_Stage ? "bg-[#583ab5]  py-2 px-4 rounded-lg mr-5" : "border-solid border-2 border-white mr-5  py-2 px-4 rounded-lg"}>
              Mint Stage
            </button>
            <button onClick={() => {
              Set_Mint_Stage(false);
              Set_WhiteList(true);
            }} className={WhiteList ? "bg-[#583ab5]  py-2 px-4 rounded-lg" : "border-solid border-2 border-white  py-2 px-4 rounded-lg"}>
              Whitelist
            </button>
          </div>
          {
            Mint_Stage &&
            <div className="w-full lg:w-[100%] flex flex-col gap-6 lg:gap-4 justify-center items-start">

              <h1 className="font-PixelEmulator text-[26px] text-white lg:text-start text-center">
                OUR LOVE ALLOWIST SIGNUP
              </h1>
              <p className="font-TerminalMedium text-[16px] text-white">
                Requirements
              </p>

              <div className="w-full lg:w-[90%] font-TerminalMedium text-white text-[14px] md:font-base leading-8 p-[8px] lg:p-4 border-solid border-2 border-white rounded-lg tracking-[3px] lg:tracking-[4px]">
                You must be in the NitftyTunnes Discord server. you must also have
                at least one of the following roles:Lovelisted
              </div>

              <div className="w-full lg:w-[90%] text-[14px] md:font-base font-TerminalMedium flex justify-between items-center p-[8px] lg:p-4 border-solid border-2 border-white rounded-lg text-white tracking-[3px] lg:tracking-[4px]">
                Connect solona wallet
                <button className="bg-[#583ab5] whitespace-nowrap py-2 px-5 rounded-lg  ">
                  Connect wallet
                </button>
              </div>

              <div className="w-full lg:w-[90%] text-[14px] md:font-base font-TerminalMedium flex justify-between items-center p-[8px] lg:p-4 border-solid border-2 border-white rounded-lg text-white tracking-[3px] lg:tracking-[4px]">
                Login to your me account
                <button className="bg-[#583ab5] whitespace-nowrap py-2 px-14 rounded-lg  ">
                  Login
                </button>
              </div>

              <div className="w-full lg:w-[90%] text-[14px] md:font-base font-TerminalMedium flex justify-between items-center p-[8px] lg:p-4 border-solid border-2 border-white rounded-lg text-white tracking-[3px] lg:tracking-[4px]">
                Connect to discord
                <button className="bg-[#583ab5] whitespace-nowrap py-2 px-12 rounded-lg ">
                  Discord
                </button>
              </div>

              <button className="bg-[#583ab5] py-3 font-TerminalMedium w-full lg:w-[90%]  rounded-lg  text-white">
                Verify and Enter
              </button>
              <p className="w-full lg:w-[74%]  font-TerminalMedium flex justify-start items-center text-white">
                By clicking "verify and enter" you agree to our term of service
              </p>
            </div>
          }
          {
            WhiteList &&
            <div className="w-full lg:w-[100%] flex flex-col gap-6 lg:gap-4 justify-center items-start">
              <div className="lg:w-[80%] w-full flex justify-between items-center " >
                <div className="flex items-center justify-center" >
                  <AiFillLock size={20} color="white" />
                  <h1 className="font-PixelEmulator text-[16px] text-white lg:text-start text-center ml-2">
                    whitelist
                  </h1>
                </div>
                <h1 className="font-PixelEmulator text-[16px] text-white lg:text-start text-center">
                  ENDED
                </h1>
              </div>
              <h1 className="font-PixelEmulator text-[18px] text-white lg:text-start text-center mt-4">
                whitelist 232 - UNLIMITED - Price 1.000
              </h1>


              <div className="border-2 rounded-[12px] border-[#583AB5] w-full p-3 mt-2" >
                <div className="flex items-start justify-center flex-col" >
                  <div className="w-full flex justify-between items-center " >
                    <div className="flex items-center justify-center" >
                      <AiFillLock size={20} color="#e42575" />
                      <h1 className="font-PixelEmulator text-[16px] text-white lg:text-start text-center ml-2">
                        public
                      </h1>
                    </div>
                    <div className="flex justify-center items-center " >
                      <h1 className="font-PixelEmulator text-[16px] text-[#583AB5] lg:text-start text-center">
                        ENDS IN
                      </h1>
                      <div className="ml-3 w-[30px] h-[30px] flex justify-center items-center rounded-sm bg-[rgba(255,255,255,0.2)]" >
                        <p className="text-white text-[14px]" >00</p>
                      </div>
                      <div className="ml-1 w-[30px] h-[30px] flex justify-center items-center rounded-sm bg-[rgba(255,255,255,0.2)]" >
                        <p className="text-white text-[14px]" >00</p>
                      </div>
                      <div className="ml-1 w-[30px] h-[30px] flex justify-center items-center rounded-sm bg-[rgba(255,255,255,0.2)]" >
                        <p className="text-white text-[14px]" >00</p>
                      </div>
                      <div className="ml-1 w-[30px] h-[30px] flex justify-center items-center rounded-sm bg-[rgba(255,255,255,0.2)]" >
                        <p className="text-white text-[14px]" >00</p>
                      </div>
                    </div>
                  </div>
                  <h1 className="font-PixelEmulator text-[18px] text-white lg:text-start text-center mt-4">
                    UNLIMITED - Price 1.000
                  </h1>
                </div>
              </div>
            </div>
          }
        </div>

        {/* right right */}
        <div className="w-full lg:w-[50%] xl:w-[40%] font-TerminalMedium relative mt-12 lg:mt-0">
          <img src="/lunch3.png" alt="" className="w-full object-cover" />
          <div className="flex flex-col justify-center items-center md:absolute md:mt-0 -mt-20 w-full md:bottom-[-30px] lg:px-10">
            <div className="w-full flex justify-between items-center text-white">
              <p>Total Minted</p>
              <p>15% (152/1000)</p>
            </div>
            <Slider
              defaultValue={30}
              sx={{
                width: "100%",
                color: "#583ab5",
              }}
            />

            <div className=" w-full flex flex-col font-TerminalMedium justify-center items-centerw-full text-white bg-[#231852] p-4 rounded-lg">
              <p>price</p>
              <p>1.50</p>
              <div className="flex justify-center items-center mt-2">
                <button className="bg-[#583ab5] whitespace-nowrap px-2 lg:px-0 py-2 px text-[14px] md:font-base rounded-lg mr-3 lg:mr-5 w-[50%]">
                  Wallet Not Connected
                </button>
                <button className="bg-[#583ab5] whitespace-nowrap py-2 px-2 lg:px-0 text-[14px] md:font-base rounded-lg w-[50%]">
                  Buy With Credit Card
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstSection;
