import React from "react";
import Nfts from "./Nfts";



function UpcommingLaunches() {
  return (
    <div className="w-full flex items-center justify-center flex-col py-[8rem] lg:px-4 px-8">
        <h1 className="font-PixelEmulator text-center lg:text-start text-[35px] lg:[20px] text-white mb-[4rem] ">
          OUR UPCOMING LAUNCHES
        </h1>
      <div className="w-full flex items-center justify-center">
      <Nfts/>
      </div>
    </div>
  );
}

export default UpcommingLaunches;
